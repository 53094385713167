import { clientInit } from '@jill64/sentry-sveltekit-cloudflare';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import * as Sentry from '@sentry/svelte';

const onError = clientInit(PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		tunnel: '/sentry',
		integrations: [new Sentry.Replay()]
	}
});

export const handleError = onError();
